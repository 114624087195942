
.envolto{
    background-color: rgb(238, 238, 238);

}


.header-contact {
  background-color: white;
  box-sizing: border-box;
  height: 250px;

  text-align: center;
  margin: 0 auto 70px;
  margin-top: 0;
  width: 70%;
  border-radius: 6px;
  margin-left: auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  font: 600 1rem Archivo;
}

.grid-section {
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  height: auto;

  margin: 0 auto 70px;

  width: 70%;
  border-radius: 6px;
  margin-left: auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  font: 600 1rem Archivo;
}
.grid-section, h1{
    align-items: center;
    text-justify: auto;
    text-align: center;
    
}
.grid-section iframe{

margin-bottom: 1rem;
}


@media(min-width:1100px){
.grid-section iframe{
margin-bottom: auto;
}

}